import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { SiTwitter, SiDiscord } from "react-icons/si";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

const data = {
    title: "",
    rows: [
        {
            title: "What is the Mint date?",
            content: "Minting will take place on October 30, 2021.",
        },
        {
            title: "What is the Mint price?",
            content:
                "Mint price is 0.05 ETH. The number of NFTs you will be able to purchase per transaction is limited to 10 pcs.",
        },
        {
            title: "Where is my NFT stored after I purchase it?",
            content: `Your NFTs will be in the wallet you used for purchase. You will see the transaction history at your wallet address. 
			Beside that, you can view all minted tokens on Opensea.`,
        },
        {
            title: "My NFTs did not appear on Opensea. Why?",
            content: `Normally, they appear within a minute after the purchase. However, at times it might take a little longer for OpenSea 
			to update, but no longer than 10 minutes, which is most unlikely. Don’t worry! Just wait and refresh the page to try again.`,
        },
		{
            title: "Can I resell my NFTs?",
            content: `Sure! You can list your NFTs for sale on OpenSea.`,
        },
		{
            title: "What else can I do with my NFTs?",
            content: `You can choose to either hold the NFT, or sell it. When you purchase the NFT, you become not only the owner of the NFT 
			and the underlying artwork, but also the beneficiary of the cartoon release and the game sale. Please read the project description 
			for more detail. We grant you a worldwide, royalty-free license to use, copy, and display the purchased art, along with any 
			extensions that you choose to create or use, solely for your own personal, non-commercial use. The rights of ownership described 
			above are bound to the underlying NFT via the smart contract. Any transfer of the NFT token will also transfer the rights 
			completely to the new owner.`,
        },
		{
            title: "How do I get the maximum profit from the purchase of the NFTs?",
            content: `If you sell the NTF, you can benefit from the significant price growth. If you choose to hold the purchased NFTs you 
			will benefit from the cartoon release and the game sale. The cartoon will be released after the NFT sale. 50% of profit from its 
			release will be distributed among the NFT holders. The next step is the release of the game. 50% of profit from the game sale 
			will be distributed between the holders on a permanent basis. The NFT holders will benefit from the cartoon and game release in 
			proportion to their share and the NFT rarity.`,
        },
		{
            title: "What is the tokenomics?",
            content: `We guarantee the exclusivity of the asset. The maximum supply of 1500 NFTs will be sold at the initial mint price of 
			0.05 ETH per unit. As soon as the maximum supply is reached, the smart contract is not capable of minting any more. The maximum 
			number of Jumping Pumpkin NFTs is forever unchangeable at 1500.`,
        },
		{
            title: "Is the purchased artwork unique?",
            content: `Totally! Each NFT image is unique and has been algorithmically generated using a combination of different attributes.`,
        },
    ],
};

const styles = {
    bgColor: '#000',
	arrowColor: "#d89c31",
	rowContentPaddingLeft: '20px',
	rowContentPaddingRight: '20px',
	titlePaddingLeft: '20px',
	titlePaddingRight: '20px',
	rowContentTextSize: '16px',
	titleTextSize: '24px',
	rowContentColor: '#fff'
};

const config = {
    animate: true,
    //arrowIcon: "V",
    //tabFocus: true
};

export default function Footer () {

    return (
        <div>
		    <h1 className="faq-title">FAQ</h1>
            <Faq
                data={data}
                styles={styles}
                config={config}
				className="faq"
            />
            <div className="logo">
			  <img src={"https://drive.google.com/uc?id=1GZcdZDVgvKX4WOTzTrwZHCpwJRWwhPkx"} className="logo-img" alt="img" />  
			</div>
			<div className='icons-footer'>
		      <a href="https://twitter.com/JumpingPumpkinn" className='icon-twitter'>
		        <SiTwitter size={25}/>
			  </a>
			  <a href="https://discord.com/invite/VBypdchgan" className='icon-discord'>
		        <SiDiscord size={25}/>
			  </a>
		    </div>
        </div>
    );
}

