import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import MediaQuery from 'react-responsive'
import { isImg } from './utils';
import FlipCountdown from '@rumess/react-flip-countdown';
import { SiTwitter, SiDiscord } from "react-icons/si";

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
		  <div className='icons'>
		    <a href="https://twitter.com/JumpingPumpkinn" className='icon-twitter'>
		      <SiTwitter size={25}/>
			</a>
			<a href="https://discord.com/invite/VBypdchgan" className='icon-discord'>
		      <SiDiscord size={25}/>
			</a>
		  </div>
          <Button onClick={event =>  window.location.href='https://mint.jumpingpumpkin.art/'} key="button" {...dataSource.button}>
            {dataSource.button.children}
          </Button>
		  <FlipCountdown
		    hideYear
            hideMonth
			titlePosition='top'
			size='small'
            theme='dark' // Options (Default: dark): dark, light.
            endAt={'2021-10-31 02:59:59'} // Date/Time
			className="banner0-countdown"
          />
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
