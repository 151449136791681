import React, { useEffect, useState } from "react";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';


export default function Footer () {

    return (
        <div>
		    <h1 className="partners-title">As Seen On:</h1>
            <div className="partners">
			  <a href="https://nftcalendar.io/event/jumping-pumpkin/">
			    <img src={"https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/nft_calendar_logo.png"} className="nftcalendar-img" alt="img" />  
			  </a>
			  <a href="https://nextdrop.is/project/2364">
			    <img src={"https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/next_drop_logo.png"} className="nextdrop-img" alt="img" />  
			  </a>
			  <a href="https://www.nftcatcher.io/drops/jumping-pumpkin">
			    <img src={"https://raw.githubusercontent.com/jumpingpumpkin/collection/4e22b5191d592c10459bebc5453ae030fdcfdf95/assets/nft-catcher-wordmark-reversed.svg"} className="nftcatcher-img" alt="img" />  
			  </a>
			  <a href="https://www.niftyselects.com/drop-calendar/jumping-pumpkin">
			    <img src={"https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/nifty_logo.png"} className="nifty-img" alt="img" />  
			  </a>
			  
			</div>
        </div>
    );
}

