import React from 'react';

export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: '',
  },
  title_mobile: {
    className: 'banner0-title-mobile',
    children: '',
  },
  content: {
    className: 'banner0-content',
  },
  button: { className: 'banner0-button', children: 'Mint' },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  OverPack: { className: 'home-page content4', playScale: 0.3 },
  imgWrapper: { className: 'content4-img', md: 0, xs: 24 },
  img: {
    children: 'https://drive.google.com/uc?id=1x89LOz7GYrntj6zrKhFxwXYf3pBmJpmM',
  },
  textWrapper: { className: 'content4-text', md: 24, xs: 24 },
  title: { className: 'content4-title', children: 'Welcome to the Jumping Pumpkin World!' },
  content: {
    className: 'content4-content',
    children: (
	<span>
	  <p>The Jumping Pumpkin NFT collection is a unique artwork created for lovers of modern art and traditions of the good old days. Our collection brings together a community of people seeking one of a kind investment opportunity.</p>
	  <br/>
	  <p>Our plans are not limited to the artwork collection. Soon after the mint we will release a JP cartoon story in the genre of science fiction and will launch it at offline art events as an art object. Then it will circulate online and will embed the preview of the JP game, which will be launched after the cartoon release. The cartoon will be auctioned off and 50% of profit from its release will be distributed among the JP NFT holders.</p>
	  <br/>
	  <p>The JP game is the next event that has the same idea of the traditional legend performed in the old-school horror tradition in the context of the present day. 50% of profit from the game sale will be distributed among holders on a permanent basis.</p>
	  <br/>
	  <p>The NFT holders will benefit from the cartoon and game release in proportion to their share and the NFT rarity.</p>
	</span>
	)
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Minting Details' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/minting_2.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '0.05 ETH',
            },
            { name: 'content', children: 'Mint Price' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/minting_1.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '1500',
            },
            {
              name: 'content',
              children: 'Max Supply',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/minting_3.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '10',
            },
            {
              name: 'content',
              children: 'Max Amount per Transaction',
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 0, xs: 24 },
  img: {
    children: 'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/collection.gif',
  },
  textWrapper: { className: 'content1-text', md: 24, xs: 24 },
  title: { className: 'content1-title', children: 'The NFT-collection' },
  content: {
    className: 'content1-content',
    children:
      'The Jumping Pumpkins is a collection of 1500 unique NFTs to be minted on Ethereum blockchain.'
  },
};
export const Content20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  page: { className: 'home-page content2' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Rarity' }],
  },
  content: {
	className: 'content2-content',
	children:
	  'Rarity will be taken into account in the distribution of profits: 0.1% rarity - 30x of common share, 10.0% rarity - 3x of common share.' 
  },
  childWrapper: {
    className: 'content2-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content2-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content2-block-item',
          children: [
            {
              name: 'image',
              className: 'content2-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/rarity_1.gif',
            },
            {
              name: 'title',
              className: 'content2-block-title',
              children: '10 / 0.67%',
            },
            { name: 'content', children: 'Pandemic Pack' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content2-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content2-block-item',
          children: [
            {
              name: 'image',
              className: 'content2-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/rarity_2.gif',
            },
            {
              name: 'title',
              className: 'content2-block-title',
              children: '100 / 6.67%',
            },
            {
              name: 'content',
              children: 'Jumping Pack',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content2-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content2-block-item',
          children: [
            {
              name: 'image',
              className: 'content2-block-icon',
              children:
                'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/rarity_3.png',
            },
            {
              name: 'title',
              className: 'content2-block-title',
              children: '1390 / 92.67%',
            },
            {
              name: 'content',
              children: 'Pumpkin Pack',
            },
          ],
        },
      },
    ],
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://drive.google.com/uc?id=1x89LOz7GYrntj6zrKhFxwXYf3pBmJpmM',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Blogging Platform' },
  content: {
    className: 'content1-content',
    children:
      'Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description',
  },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Roadmap</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>16%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <span>
                    <h3>1500 unique NFTs</h3>
					<p>minted and distributed across the JP community</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: { className: 'block-content', children: '' },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>33%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
				  <h3>Release of JP art cartoon preview</h3>
                  <p>for showing at paid art events as a digital art object with in-built brand ads</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <p>
                    &nbsp;
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>50%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
				<h3>Art cartoon release on youtube</h3>
                <p>with a built-in advertising of the JP game</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  {' '}
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>66%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
				<h3>Royalties distribution</h3>
                <p>50% of total profit of the cartoon release for the JP NFT holders</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  {' '}
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>83%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
				<h3>“Jumping Pumpkin World” game release</h3>
                <p>Official sale of the game</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  {' '}
                </p>
              </span>
            ),
          },
        },
      },
{
        name: 'block5',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/roadmap_icon.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://raw.githubusercontent.com/jumpingpumpkin/collection/407af31e91bba72f3713374c6b3f883ed6ce8367/assets/roadmap_point.svg',
          },
          name: { className: 'block-name', children: '' },
          post: { className: 'block-post', children: '' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>100%</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
				<h3>Royalties distribution</h3>
                <p>50% of total profit of the game sale on a permanent basis for JP NFT holders</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  {' '}
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    children: [
      {
        name: 'block0',
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://raw.githubusercontent.com/jumpingpumpkin/collection/gh-pages/assets/header_logo.png',
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2021 by <a href="">Jumping Pumpkin</a> All Rights
        Reserved
      </span>
    ),
  },
};
